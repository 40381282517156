import { memo, useMemo } from 'react'
import {
	viewProject, reviewProject, creatorData, creatorAlertsAudioPanel,
	dropEvent, dropPreviewEvent,
} from 'root/src/shared/descriptions/endpoints/recordTypes'
import withModuleContext from 'root/src/client/util/withModuleContext'
import recordModuleConnector from 'root/src/client/logic/api/connectors/recordModuleConnector'
import dynamic from 'next/dynamic'
import { DynamicImportPlaceholder } from 'root/src/client/web/base/DynamicImportPlaceholder'

const recordModuleTypes = {
	[viewProject]: dynamic(() => import('root/src/client/web/record/ViewProject'), { loading: () => <DynamicImportPlaceholder /> }),
	[reviewProject]: dynamic(() => import('root/src/client/web/record/ReviewProject'), { loading: () => <DynamicImportPlaceholder /> }),
	[creatorData]: dynamic(() => import('root/src/client/web/record/CreatorData'), { loading: () => <DynamicImportPlaceholder /> }),
	[creatorAlertsAudioPanel]: dynamic(() => import('root/src/client/web/record/CreatorAlertsAudioPanel/CreatorAlertsAudioPanelConnected'), { loading: () => <DynamicImportPlaceholder /> }),
	[dropEvent]: dynamic(() => import('root/src/client/web/record/DropEvent'), { loading: () => <DynamicImportPlaceholder /> }),
	[dropPreviewEvent]: dynamic(() => import('root/src/client/web/record/DropEvent'), { loading: () => <DynamicImportPlaceholder /> }),
}

export const RecordModuleUnconnected = memo(({ recordPageType, moduleId }) => {
	const RecordModuleComponent = useMemo(() => (
		recordModuleTypes[recordPageType]
	), [recordPageType])

	if (!RecordModuleComponent) {
		return <div>Unsupported record page type: {recordPageType}</div>
	}

	if (recordPageType === dropPreviewEvent) {
		return <RecordModuleComponent moduleId={moduleId} preview />
	}

	return <RecordModuleComponent moduleId={moduleId} />
})

export default withModuleContext(recordModuleConnector(RecordModuleUnconnected))
