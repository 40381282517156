import reduxConnector from 'root/src/shared/util/reduxConnector'

import recordPageTypeSelector from 'root/src/client/logic/api/selectors/recordPageTypeSelector'
import recordSelector from 'root/src/client/logic/api/selectors/recordSelector'

export default reduxConnector(
	[
		['recordPageType', recordPageTypeSelector],
		['record', recordSelector],
	],
)
